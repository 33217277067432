import createModel, { Silent } from "@/__main__/data-model.mjs";

export const model = Silent({
  data: {
    executeDatabricksQuery: {
      payload: {
        manifest: {
          chunks: [{ chunkIndex: Number, rowCount: Number, rowOffset: Number }],
          format: "JSON_ARRAY",
          schema: {
            columnCount: Number,
            columns: [
              {
                name: String,
                position: Number,
                typeName: String,
                typeText: String,
              },
            ],
          },
          totalChunkCount: Number,
          totalRowCount: Number,
          truncated: Boolean,
        },
        result: {
          chunkIndex: Number,
          dataArray: Silent([Silent([Silent(String)])]),
          rowCount: Number,
          rowOffset: Number,
        },
        statementId: String,
        status: {
          state: String,
        },
      },
    },
  },
});

const transform = createModel(model);

export default transform;
